import { types } from '../user'
import createReducer from '../utils/createReducer'

export const initialState = {
  authenticated: false,
}

export default createReducer(initialState, {
  [types.SIGN_IN_SUCCESS](state, action) {
    return {
      ...state,
      user: action.user,
      authenticated: true,
    }
  },
  [types.SIGN_OUT_SUCCESS](state, action) {
    return {
      ...state,
      user: null,
      authenticated: false,
    }
  },
})
