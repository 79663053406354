import { types } from '../pets'
import createReducer from '../utils/createReducer'

export const initialState = {
  currentPet: {
    parentName: '',
    email: '',
    petName: '',
    primaryBreed: 0,
    size: 0,
    age: 0,
    gender: '',
    foodPreference: '',
    bodyComposition: '',
    activityLevel: '',
    currentDietManufacturer: 0,
    currentDietName: 0,
  },
}

export default createReducer(initialState, {
  [types.UPDATE_FIELD](state, action) {
    return {
      ...state,
      currentPet: {
        ...state.currentPet,
        [action.fieldName]: action.value,
      },
    }
  },
  [types.RESET_CURRENT_PET](state) {
    return {
      ...state,
      currentPet: initialState.currentPet,
    }
  },
})
