import React from 'react'
import Site from './layout/Site'
import Header from './layout/Header'
import Content from './layout/Content'
import Footer from './layout/Footer'

const Layout = ({ signOut, children }) => {
  return (
    <Site>
      <Header signOut={signOut} />
      <Content>{children}</Content>
      <Footer />
    </Site>
  )
}

Layout.displayName = 'Layout'

export default Layout
