import { all, select, takeEvery, call } from 'redux-saga/effects'
import { types, selectors } from '../pets'

export function* saveCurrentPet() {
  // try {
  //   const currentPet = yield select(selectors.currentPet)
  //   yield call(addDoc, 'pets', currentPet)
  // } catch (error) {
  //   console.error(error)
  // }
}

export default function*() {
  yield all([takeEvery(types.SAVE, saveCurrentPet)])
}
