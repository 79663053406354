import styled from 'styled-components'

export const Divider = styled.hr`
  border-color: ${props => props.theme.BORDER_GRAY};
  margin: ${props =>
    props.position === 'above'
      ? `${props => props.theme.SPACING.LARGE} 0 ${props => props.theme.SPACING.MEDIUM} 0`
      : `${props => props.theme.SPACING.MEDIUM} 0 ${props => props.theme.SPACING.LARGE} 0`};
`

Divider.displayName = 'Divider'

export default Divider
