import React from 'react'
import styled from 'styled-components'

export const LabeledValueStyles = styled.div`
  .lv-label {
    color: ${props => props.theme.TEAL};
    font-weight: 600;
    margin-bottom: ${props => props.theme.SPACING.EXTRA_SMALL};
  }

  .lv-value {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: ${props => props.theme.SPACING.STANDARD};
  }
`

export const LabeledValue = ({ label, value }) => (
  <LabeledValueStyles>
    <div className="lv-label">{label || 'N/A'}</div>
    <div className="lv-value">{value || 'N/A'}</div>
  </LabeledValueStyles>
)

LabeledValue.displayName = 'LabeledValue'

export default LabeledValue
