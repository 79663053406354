import breeds from '../breeds/breeds.saga'
import diets from '../diets/diets.sagas'
import manufacturers from '../manufacturers/manufacturers.saga'
import pets from '../pets/pets.saga'
import shared from '../shared/shared.saga'
import targetNutrition from '../targetNutrition/targetNutrition.saga'
import user from '../user/user.sagas'
import vet from '../vet/vet.sagas'

const sagas = [breeds, diets, manufacturers, pets, shared, targetNutrition, user, vet]

const initSagas = sagaMiddleware => sagas.forEach(sagaMiddleware.run.bind(sagaMiddleware))

export default initSagas
