import { all, takeEvery, call, put } from 'redux-saga/effects'
import { API } from 'aws-amplify'
import PFRM_API from '../config/api'
import { actions, types } from '../manufacturers'

export function* fetchAllManufacturers() {
  try {
    const manufacturers = yield call([API, 'get'], PFRM_API, '/manufacturers')
    yield put(actions.set(manufacturers))
  } catch (error) {
    console.error(error)
  }
}

export function* fetchDietsForManufacturer({ manufacturerId, brandId }) {
  try {
    console.log(brandId)
    const diets = yield call(
      [API, 'get'],
      PFRM_API,
      `/manufacturers/${manufacturerId}/diets${brandId !== undefined ? `?brandId=${brandId}` : ''}`
    )
    yield put(actions.setDiets(diets))
  } catch (error) {
    console.error(error)
  }
}

export default function*() {
  yield all([
    takeEvery(types.GET, fetchAllManufacturers),
    takeEvery(types.GET_DIETS, fetchDietsForManufacturer),
  ])
}
