/**
 * Formats a number as a percentage to a specified decimal place
 * @param {number} value the decimal to convert
 * @param {number} sigFigs the number of decimal places to include
 * @throws {RangeError} - if value is not between 0 and 1
 */
const formatPercent = (value, sigFigs) => {
  if (value < 0 || value > 1) {
    throw new RangeError('value should be between 0 and 1')
  }
  return `${(value * 100).toFixed(sigFigs)}%`
}

export default formatPercent
