import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Flex from './components/Flex'
import { selectors as petSelectors } from '../store/pets'
import { selectors as breedSelectors } from '../store/breeds'
import Button from './components/Button'
import Card from './components/Card'
import PetInformationForCalories from './results/header/PetInformationForCalories'
import CalorieTarget from './results/header/CalorieTarget'
import './CaloriesResultsStyles.css'

const caloriesResultsInlineStyles = {
  h2: {
    fontSize: '1.5em',
    color: '#5EA3AF',
    margin: '0.8rem 0 0 0',
  },
  gauge: {
    marginTop: 25,
    marginBottom: 25,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '70%',
    height: 8,
    borderRadius: 16,
    background: '#ebb54b',
    background: '-moz-linear-gradient(left, #ebb54b 0%, #b6bb4e 46%, #b6bb4e 58%, #ea4444 100%)',
    background: '-webkit-linear-gradient(left, #ebb54b 0%,#b6bb4e 46%,#b6bb4e 58%,#ea4444 100%)',
    background: 'linear-gradient(to right, #ebb54b 0%,#b6bb4e 46%,#b6bb4e 58%,#ea4444 100%)',
    filter:
      "progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebb54b', endColorstr='#ea4444',GradientType=1 )",
  },
}

class CaloriesResults extends Component {
  constructor(props) {
    super(props)
    this.state = {
      minDailyCalories: '0',
      targetDailyCalories: '0',
      maxDailyCalories: '0',
    }
  }

  componentWillMount() {
    this.calculateDailyCaloricIntake(this.props.currentPet)
  }

  calculateDailyCaloricIntake = currentPet => {
    if (currentPet) {
      if (currentPet.petWeight) {
        // Waltham Calculation
        // 1 lbs = 0.454 kg
        // 110 x (body weight in kilograms)^0.75
        // Note - '110' is modified based on conditions below.

        let weightInKg = parseFloat(currentPet.petWeight) * 0.454
        let weightTo75 = Math.pow(weightInKg, 0.75)
        let calMargin = 1.1
        let calFactor = 110

        if (currentPet.pregnant === 'Yes') {
          calFactor = 104
        } else if (currentPet.age === 'Senior') {
          calFactor = 90
        } else if (currentPet.activityLevel === 'veryActive') {
          calFactor = 150
        }

        let walthamKCal = weightTo75 * calFactor
        let margin = walthamKCal * calMargin - walthamKCal

        let minDailyCalories = Math.round(walthamKCal - margin)
        let targetDailyCalories = Math.round(walthamKCal)
        let maxDailyCalories = Math.round(walthamKCal + margin)

        this.setState({ minDailyCalories, targetDailyCalories, maxDailyCalories })
      }
    }
  }

  render() {
    const breedObj = this.props.breed(this.props.currentPet.primaryBreed)
    return (
      <div>
        <Card>
          <h2 style={caloriesResultsInlineStyles.h2}>Recommended Daily Caloric Intake</h2>
          <Flex alignItems="center" justify="space-between" className="override-flex">
            <Flex flex="1 0">
              <PetInformationForCalories
                name={this.props.currentPet.petName}
                age={this.props.currentPet.age}
                petWeight={this.props.currentPet.petWeight}
                breed={breedObj && breedObj.name}
                gender={this.props.currentPet.gender}
                bodyComposition={this.props.currentPet.bodyComposition}
                activityLevel={this.props.currentPet.activityLevel}
                spayNeuter={this.props.currentPet.spayNeuter}
                pregnant={this.props.currentPet.pregnant}
                nursing={this.props.currentPet.nursing}
                climateType={this.props.currentPet.climateType}
              />
            </Flex>
            <Flex
              direction="column"
              flex="2 0"
              justify-content="center"
              className="results-padding"
            >
              <Flex justify="space-around" alignItems="center">
                <CalorieTarget label="Minimum" value={this.state.minDailyCalories} />
                <CalorieTarget
                  label="Target Calories / per Day"
                  value={this.state.targetDailyCalories}
                  isTarget={true}
                />
                <CalorieTarget label="Maximum" value={this.state.maxDailyCalories} />
              </Flex>
              <div style={caloriesResultsInlineStyles.gauge} />
            </Flex>
            <Flex justify="flex-end" flex="1 0">
              <Button primary className="btn-full-width">
                Email Results
              </Button>
            </Flex>
          </Flex>
        </Card>
        <Link to="/calories" className="btn-link">
          &#8592;&nbsp;Back to Calorie Calculator
        </Link>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentPet: petSelectors.currentPet(state),
    breed: id => breedSelectors.breed(id)(state),
  }
}

CaloriesResults.defaultProps = {
  displayName: 'Calorie Results',
}

export default connect(mapStateToProps)(CaloriesResults)
