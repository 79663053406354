// types
export const types = {
  SAVE: 'pets/SAVE',
  RESET_CURRENT_PET: 'pets/RESET_CURRENT_PET',
  UPDATE_FIELD: 'pets/UPDATE_FIELD'
}

// action creators
const resetCurrentPet = () => ({
  type: types.RESET_CURRENT_PET
})

const saveNewPet = () => ({
  type: types.SAVE
})

const updateField = (fieldName, value) => ({
  type: types.UPDATE_FIELD,
  fieldName,
  value
})

export const actions = {
  resetCurrentPet,
  saveNewPet,
  updateField
}

// selectors
export const selectors = {
  currentPet: state => state.pets.currentPet
}

export default actions
