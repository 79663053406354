import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Layout from './app/Layout'
import { actions } from './store/shared'
import { selectors as userSelectors, actions as userActions } from './store/user'
import Home from './app/Home'
// import VetDashboard from './app/VetDashboard'
import Results from './app/Results'
import Calories from './app/Calories'
import CaloriesResults from './app/CaloriesResults'

const App = ({ authState, authData, dispatch }) => {
  useEffect(() => {
    if (authState === 'signedIn') {
      dispatch(actions.getRefData())
    }
  }, [authState, dispatch])

  return (
    authState === 'signedIn' && (
      <Router>
        <Switch>
          <Layout signOut={() => dispatch(userActions.signOut())}>
            <Route path="/" exact component={Home} />
            <Route path="/results" component={Results} />
            <Route path="/calories" component={Calories} />
            <Route path="/calories-results" component={CaloriesResults} />
          </Layout>
        </Switch>
      </Router>
    )
  )
}
const mapStateToProps = /* istanbul ignore next */ state => ({
  user: userSelectors.user(state),
})

App.displayName = 'App'
export default connect(mapStateToProps)(App)
