// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "pfrm-react-20190424135052-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://pfrm-react-20190424135052-hostingbucket-prod.s3-website-us-east-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "us-east-1:f1389dcc-5101-44fc-917e-e0a6d8a7c9da",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_wHJMdmDDB",
    "aws_user_pools_web_client_id": "1k9t3bm650i5ka9qt4duslvms6",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "pfrmapi",
            "endpoint": "https://zfv2dkm2aa.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
