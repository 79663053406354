import styled from 'styled-components'

export const Card = styled.div`
  background-color: ${props => props.theme.WHITE};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  padding: ${props => props.theme.SPACING.EXTRA_LARGE};
  margin: ${props => props.theme.SPACING.EXTRA_LARGE} auto;
  overflow: hidden;
  position: relative;
  &:before {
    content: ' ';
    height: ${props => props.theme.SPACING.SMALL};
    background-color: ${props =>
      props.bannerColor ? props.bannerColor : props => props.theme.TEAL};
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`

Card.displayName = 'Card'

export default Card
