import { all, takeEvery, put, call } from 'redux-saga/effects'
import { API } from 'aws-amplify'
import { actions, types } from '../breeds'

export function* fetchAllBreeds() {
  try {
    const breeds = yield call([API, 'get'], 'pfrmapi', '/breeds')
    yield put(actions.set(breeds))
  } catch (error) {
    console.log(error)
  }
}

export default function*() {
  yield all([takeEvery(types.GET, fetchAllBreeds)])
}
