import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import DietCard from './results/DietCard'
import Flex from './components/Flex'
import Header from './results/Header'
import { actions as dietActions, selectors as dietSelectors } from '../store/diets'
import { selectors as petSelectors } from '../store/pets'
import { selectors as breedSelectors } from '../store/breeds'
import {
  actions as targetNutritionActions,
  selectors as targetNutritionSelectors,
} from '../store/targetNutrition'

export const Results = ({
  currentDiet,
  currentPet,
  relatedDiets,
  targetNutrition,
  breedSelect,
  dispatch,
}) => {
  useEffect(() => {
    dispatch(targetNutritionActions.get())
    // dispatch(dietActions.getCurrentDiet())
  }, [dispatch])

  return !currentPet.age ? (
    <Redirect to={'/'} />
  ) : targetNutrition ? (
    <div>
      <Header
        currentPet={currentPet}
        targetNutrition={targetNutrition}
        breed={breedSelect(currentPet.primaryBreed)}
      />
      <Flex justify="space-between" wrap="wrap">
        {/* <DietCard diet={currentDiet} title="Current Diet" /> */}
        {relatedDiets.map(d => (
          <DietCard key={d.productFamilyId} diet={d} />
        ))}
      </Flex>
    </div>
  ) : null
}

const mapStateToProps = /* istanbul ignore next */ state => ({
  currentDiet: dietSelectors.currentDiet(state),
  currentPet: petSelectors.currentPet(state),
  relatedDiets: dietSelectors.relatedDiets(state),
  targetNutrition: targetNutritionSelectors.targetNutrition(state),
  breedSelect: id => breedSelectors.breed(id)(state),
})

Results.displayName = 'Results'

export default connect(mapStateToProps)(Results)
