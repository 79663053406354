import { types } from '../vet'
import createReducer from '../utils/createReducer'

export const initialState = {
  petParents: [],
}

export default createReducer(initialState, {
  [types.SET_PET_PARENTS](state, action) {
    return {
      ...state,
      petParents: action.petParents,
    }
  },
})
