import styled from 'styled-components'

export const Header = styled.h3`
  border: 0;
  border-radius: 0;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : props => props.theme.BLUE};
  font-size: 1.4rem;
  text-transform: uppercase;
  color: ${props => props.theme.WHITE};
  text-align: center;
  margin-bottom: 0;
  padding: ${props => props.theme.SPACING.MEDIUM} ${props => props.theme.SPACING.STANDARD};
`

Header.displayName = 'Header'

export default Header
