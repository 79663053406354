import { all, takeEvery, call, put } from 'redux-saga/effects'
import { Auth } from 'aws-amplify'
import { types, actions } from '../user'

export function* signIn({ username, password }) {
  try {
    let user = yield call([Auth, 'currentAuthenticatedUser'])
    yield put(actions.signInSuccess(user))
    // yield call([Auth, 'signIn'], username, password)
  } catch (error) {
    console.error(error)
  }
}

export function* signOut() {
  try {
    yield call([Auth, 'signOut'])
  } catch (error) {
    console.error(error)
  }
}

export default function*() {
  yield all([takeEvery(types.SIGN_IN, signIn), takeEvery(types.SIGN_OUT, signOut)])
}
