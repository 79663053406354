import styled from 'styled-components'

export const Content = styled.div`
  flex: 1;
  margin-top: 1.8rem;
`

Content.displayName = 'Content'

export default Content
