import React from 'react'
import { withTheme } from 'styled-components'
import Flex from '../components/Flex'
import Button from '../components/Button'
import Divider from '../components/Divider'

export const Actions = ({ onReset, onSave, theme, submitLabel }) => (
  <div>
    <Divider position="above" />
    <Flex justify="flex-end">
      <Button type="button" onClick={onReset}>
        Reset
      </Button>
      <Button type="button" primary margin={`0 0 0 ${theme.SPACING.STANDARD}`} onClick={onSave}>
        {submitLabel || 'Submit'}
      </Button>
    </Flex>
  </div>
)

Actions.displayName = 'Actions'

export default withTheme(Actions)
