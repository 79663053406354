//types
export const types = {
  GET: 'targetNutrition/GET',
  SET: 'targetNutrition/SET',
}

// action creators
/**
 * dispatches targetNutrition/GET
 */
const get = () => {
  return { type: types.GET }
}

/**
 * dispatches targetNutrition/SET
 * @param {Object} targetNutrition
 */
const set = targetNutrition => ({ type: types.SET, targetNutrition })

export const actions = {
  get,
  set,
}

// selectors
/**
 * gets target nutrition values from state
 * @param {Object} state
 */
const targetNutrition = state => state.targetNutrition.targetNutrition

export const selectors = {
  targetNutrition,
}

export default actions
