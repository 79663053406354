import React from 'react'
import styled from 'styled-components'
import logo from '../../static/logo_white.svg'
import Flex from '../components/Flex'
import Button from '../components/Button'

const HeaderStyles = styled(Flex)`
  background-color: ${props => props.theme.TEAL};
  padding: ${props => props.theme.SPACING.STANDARD};
  margin: 0 -${props => props.theme.SPACING.STANDARD};
  color: ${props => props.theme.WHITE};
  img {
    width: 150px;
  }
`

const Header = ({ signOut }) => (
  <HeaderStyles justify="space-between" alignItems="flex-end">
    <img src={logo} alt="Pet Food Reference Manual" />
    <Button onClick={signOut} link inverted>
      Logout
    </Button>
  </HeaderStyles>
)

HeaderStyles.displayName = 'HeaderStyles'
Header.displayName = 'Header'

export default Header
