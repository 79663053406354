import { types } from '../targetNutrition'

export const initialState = {
  age: '',
  breedSize: '',
  protein: 0.0,
  fat: 0.0,
  fiber: 0.0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET:
      return {
        ...state,
        targetNutrition: action.targetNutrition,
      }
    default:
      return state
  }
}
