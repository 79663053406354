import React from 'react'
import styled, { withTheme } from 'styled-components'
import Card from '../components/Card'
import Divider from '../components/Divider'
import Flex from '../components/Flex'
import formatPercent from '../../util/formatPercent'
import LabelValue from './dietCard/LabeledValue'
import Panel from './dietCard/Panel'
import Title from './dietCard/Title'

const DietCardStyles = styled(Card)`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 ${props => props.theme.SPACING.EXTRA_LARGE} 0 0;

  &:last-child {
    margin: 0;
  }
`

export const DietCard = ({ diet, title, theme }) => (
  <DietCardStyles>
    <Title>{title}</Title>
    <Divider />

    <LabelValue label="Brand" value={diet.manufacturerBrandName} />
    <LabelValue label="Diet" value={diet.productName} />

    <Flex direction="column" alignItems="stretch">
      <Panel
        header="Diet Information"
        headerBackgroundColor={theme.ORANGE}
        data={[
          ['Type', diet.packageForm || 'N/A'],
          ['Cost Per Cup', diet.costPerCup || 'N/A'],
          ['Kcal/Cup', diet.kcalPerCup || 'N/A'],
          ['Kcal/kg (ME)', diet.kcalPerKg || 'N/A'],
        ]}
      />
      <Panel
        header="Guaranteed Analysis"
        headerBackgroundColor={theme.YELLOW}
        data={[
          [
            'Protein Min',
            diet.guaranteedAnalysis && diet.guaranteedAnalysis.protein
              ? formatPercent(diet.guaranteedAnalysis.protein)
              : 'N/A',
          ],
          [
            'Fat Min',
            diet.guaranteedAnalysis && diet.guaranteedAnalysis.fat
              ? formatPercent(diet.guaranteedAnalysis.fat)
              : 'N/A',
          ],
          [
            'Fiber Max',
            diet.guaranteedAnalysis && diet.guaranteedAnalysis.fiber
              ? formatPercent(diet.guaranteedAnalysis.fiber)
              : 'N/A',
          ],
        ]}
      />
      {/* <Panel
        header="Dry Weight"
        collapsible
        data={[
          ['Dry Percent', formatPercent(diet.dryPercent)],
          ['Dry Protein', formatPercent(diet.dryProtein)],
          ['Dry Fat', formatPercent(diet.dryFat)],
          ['Dry Fiber', formatPercent(diet.dryFiber)],
        ]}
      />
      <Panel
        header="Ingredients"
        collapsible
        headerBackgroundColor={theme.GREEN}
        data={diet.ingredients ? sortOrderedObject(diet.ingredients) : []}
      /> */}
    </Flex>
  </DietCardStyles>
)

DietCard.displayName = 'DietCard'

DietCard.defaultProps = {
  title: 'Recommended Diet',
}

export default withTheme(DietCard)
