import styled from 'styled-components'
import Item from './flex/Item'

export const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  flex-wrap: ${props => props.wrap || 'nowrap'};
  justify-content: ${props => props.justify || 'start'};
  align-items: ${props => props.alignItems || 'stretch'};
  align-content: ${props => props.alignContent || 'start'};
  order: ${props => props.order || '0'};
  flex: ${props => props.flex || '0 1 auto'};
  align-self: ${props => props.alignSelf || 'auto'};
`

Flex.Item = Item

Flex.displayName = 'Flex'

export default Flex
