import styled from 'styled-components'

export const Site = styled.div`
  font-family: 'Open Sans', 'Arial', sans-serif;
  padding: 0 ${props => props.theme.SPACING.STANDARD};
  background-color: ${props => props.theme.BACKGROUND};
  color: ${props => props.theme.DEFAULT_TEXT};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  .bold {
    font-weight: bold;
  }

  @media screen {
    html,
    body {
      height: 100%;
    }
    .collapse-padding {
      padding: 0 !important;
    }
    hr {
      border-color: #bfbfbf;
    }
    .clickable {
      cursor: pointer;
    }
    .page-wrap {
      margin-top: 18px;
    }
  }

  @media print {
    body {
      font-size: 11px;
    }
    table {
      page-break-inside: auto;
    }
    tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }
  }
`

Site.displayName = 'Site'

export default Site
