// types
export const types = {
  GET: 'manufacturers/GET',
  SET: 'manufacturers/SET',
  GET_DIETS: 'manufacturers/GET_DIETS',
  SET_DIETS: 'manufacturers/SET_DIETS',
}

// action creators
const get = () => ({ type: types.GET })
const set = manufacturers => ({ type: types.SET, manufacturers })
const getDiets = (manufacturerId, brandId) => ({ type: types.GET_DIETS, manufacturerId, brandId })
const setDiets = diets => ({ type: types.SET_DIETS, diets })

export const actions = {
  get,
  set,
  getDiets,
  setDiets,
}

// selectors
export const selectors = {
  manufacturers: state => state.manufacturers.manufacturers,
  diets: state => state.manufacturers.diets,
}

export default actions
