import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { pipe } from 'ramda'
import { selectors as breedSelectors } from '../store/breeds'
import { selectors as petSelectors, actions as petActions } from '../store/pets'
import preventDefaultEvent from '../util/preventDefaultEvent'
import Divider from './components/Divider'
import TextBox from './home/TextBox'
import Actions from './home/Actions'
import USStates from '../static/listOfUSStates.json'

export const Calories = ({ breeds, currentPet, saveNewPet, history, updateField, resetForm }) => {
  const updateFieldHandler = event => {
    updateField([event.currentTarget.name], event.currentTarget.value)
    return event.currentTarget.value
  }

  const saveNewPetHandler = pipe(
    preventDefaultEvent,
    () => {
      saveNewPet()
      history.push('/calories-results')
    }
  )

  const getIsPregnantUI = isFemale => {
    if (isFemale.toLowerCase() === 'female') {
      return (
        <div className="form-group">
          <label htmlFor="activityLevel" className="col-sm-2 control-label">
            Pregnant
          </label>
          <div className="col-sm-10">
            <label className="radio-inline">
              <input
                type="radio"
                name="pregnant"
                id="pregnant1"
                value="Yes"
                onChange={updateFieldHandler}
                required
              />{' '}
              Yes
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="pregnant"
                id="pregnant2"
                value="No"
                onChange={updateFieldHandler}
                required
              />{' '}
              No
            </label>
          </div>
        </div>
      )
    }
  }

  const getIsNursingUI = isFemale => {
    if (isFemale.toLowerCase() === 'female') {
      return (
        <div className="form-group">
          <label htmlFor="activityLevel" className="col-sm-2 control-label">
            Nursing
          </label>
          <div className="col-sm-10">
            <label className="radio-inline">
              <input
                type="radio"
                name="nursing"
                id="nursing1"
                value="Yes"
                onChange={updateFieldHandler}
                required
              />{' '}
              Yes
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="nursing"
                id="nursing2"
                value="No"
                onChange={updateFieldHandler}
                required
              />{' '}
              No
            </label>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="container">
      <h4>Your Pet's Caloric Intake</h4>
      <p>
        The following information will help us calculate the number of calories per day your pet
        needs to stay happy and healthy.
      </p>
      <Divider />
      <form className="form-horizontal">
        {/* <TextBox
                    labelText="Pet Parent Email"
                    id="email"
                    placeholder="Pet Parent email"
                    value={currentPet.email}
                    onChange={updateFieldHandler} /> */}

        <TextBox
          labelText="Pet Name"
          id="petName"
          placeholder="Pet Name"
          value={currentPet.petName}
          onChange={updateFieldHandler}
        />

        <div className="form-group">
          <label htmlFor="age" className="col-sm-2 control-label">
            Age
          </label>
          <div className="col-sm-10">
            <label className="radio-inline">
              <input
                type="radio"
                name="age"
                id="age1"
                value="Puppy"
                required
                onChange={updateFieldHandler}
              />{' '}
              Puppy (less than 1 Yr)
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="age"
                id="age2"
                value="Junior"
                required
                onChange={updateFieldHandler}
              />{' '}
              Junior (1-2 Yrs)
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="age"
                id="age3"
                value="Adult"
                required
                onChange={updateFieldHandler}
              />{' '}
              Adult (3-6 Yrs)
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="age"
                id="age4"
                value="Senior"
                required
                onChange={updateFieldHandler}
              />{' '}
              Senior (6-9 Yrs)
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="age"
                id="age5"
                value="Senior"
                required
                onChange={updateFieldHandler}
              />{' '}
              Geriatric (over 9 Yrs)
            </label>
          </div>
        </div>

        <TextBox
          labelText="Pet Weight (in Lbs)"
          id="petWeight"
          placeholder="e.g. 50"
          value={currentPet.petWeight}
          onChange={updateFieldHandler}
        />

        <div className="form-group">
          <label htmlFor="breed" className="col-sm-2 control-label">
            Primary Breed
          </label>
          <div className="col-sm-10">
            <select
              className="form-control"
              id="primaryBreed"
              name="primaryBreed"
              value={currentPet.primaryBreed}
              onChange={updateFieldHandler}
              required
            >
              <option value="">--</option>
              {breeds.map(b => (
                <option value={b.id} key={b.id}>
                  {b.name}
                </option>
              ))}
              <option value="Other">Other</option>
            </select>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="gender" className="col-sm-2 control-label">
            Gender
          </label>
          <div className="col-sm-10">
            <label className="radio-inline">
              <input
                type="radio"
                name="gender"
                id="gender1"
                value="Male"
                onChange={updateFieldHandler}
                required
              />{' '}
              Male
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="gender"
                id="gender2"
                value="Female"
                onChange={updateFieldHandler}
                required
              />{' '}
              Female
            </label>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="bodyComposition" className="col-sm-2 control-label">
            Body Composition
          </label>
          <div className="col-sm-10">
            <label className="radio-inline">
              <input
                type="radio"
                name="bodyComposition"
                id="bodyComposition1"
                value="underWeight"
                onChange={updateFieldHandler}
                required
              />{' '}
              Underweight
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="bodyComposition"
                id="bodyComposition2"
                value="ideal"
                onChange={updateFieldHandler}
                required
              />{' '}
              Ideal
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="bodyComposition"
                id="bodyComposition3"
                value="overWeight"
                onChange={updateFieldHandler}
                required
              />{' '}
              Overweight
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="bodyComposition"
                id="bodyComposition4"
                value="obese"
                onChange={updateFieldHandler}
                required
              />{' '}
              Obese
            </label>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="activityLevel" className="col-sm-2 control-label">
            Activity Level
          </label>
          <div className="col-sm-10">
            <label className="radio-inline">
              <input
                type="radio"
                name="activityLevel"
                id="activityLevel1"
                value="lessActive"
                onChange={updateFieldHandler}
                required
              />{' '}
              Less Active
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="activityLevel"
                id="activityLevel2"
                value="normal"
                onChange={updateFieldHandler}
                required
              />{' '}
              Normal
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="activityLevel"
                id="activityLevel3"
                value="veryActive"
                onChange={updateFieldHandler}
                required
              />{' '}
              Very Active
            </label>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="activityLevel" className="col-sm-2 control-label">
            Spayed or Neutered
          </label>
          <div className="col-sm-10">
            <label className="radio-inline">
              <input
                type="radio"
                name="spayNeuter"
                id="spayNeuter1"
                value="spayedNeutered"
                onChange={updateFieldHandler}
                required
              />{' '}
              Yes
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="spayNeuter"
                id="spayNeuter2"
                value="normal"
                onChange={updateFieldHandler}
                required
              />{' '}
              No
            </label>
          </div>
        </div>

        {getIsPregnantUI(currentPet.gender)}
        {getIsNursingUI(currentPet.gender)}

        <div className="form-group">
          <label htmlFor="currentDiet" className="col-sm-2 control-label">
            Climate Type
          </label>
          <div className="col-sm-10">
            <select
              className="form-control"
              id="climateType"
              name="climateType"
              onChange={updateFieldHandler}
              value={currentPet.climateType}
              required
            >
              <option value="">Select a State</option>
              {Object.keys(USStates).map((key, index) => {
                return (
                  <option value={key} key={index}>
                    {USStates[key]}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        <Actions onReset={resetForm} onSave={saveNewPetHandler} submitLabel="Calculate" />
      </form>
    </div>
  )
}

const mapStateToProps = /* istanbul ignore next */ state => ({
  breeds: breedSelectors.breeds(state),
  currentPet: petSelectors.currentPet(state),
})

const mapDispatchToProps = /* istanbul ignore next */ dispatch => ({
  resetForm: bindActionCreators(petActions.resetCurrentPet, dispatch),
  saveNewPet: bindActionCreators(petActions.saveNewPet, dispatch),
  updateField: bindActionCreators(petActions.updateField, dispatch),
})

Calories.displayName = 'Calories'

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Calories)
