import { types } from '../shared'
import createReducer from '../utils/createReducer'

export const initialState = {
  asyncOperationsCount: 0
}

export default createReducer(initialState, {
  [types.ASYNC_START](state) {
    return {
      ...state,
      asyncOperationsCount: state.asyncOperationsCount + 1
    }
  },
  [types.ASYNC_END](state) {
    return {
      ...state,
      asyncOperationsCount: state.asyncOperationsCount >= 1 ? state.asyncOperationsCount - 1 : 0
    }
  }
})
