import { combineReducers } from 'redux'
import breeds from '../breeds/breeds.store'
import diets from '../diets/diets.store'
import manufacturers from '../manufacturers/manufacturers.store'
import pets from '../pets/pets.store'
import shared from '../shared/shared.store'
import targetNutrition from '../targetNutrition/targetNutrition.store'
import user from '../user/user.store'
import vet from '../vet/vet.store'

const rootReducer = combineReducers({
  breeds,
  diets,
  manufacturers,
  pets,
  shared,
  targetNutrition,
  user,
  vet,
})

export default rootReducer
