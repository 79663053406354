import { types } from '../manufacturers'
import createReducer from '../utils/createReducer'

export const initialState = {
  manufacturers: [],
  diets: [],
}

export default createReducer(initialState, {
  [types.SET](state, action) {
    return {
      ...state,
      manufacturers: action.manufacturers,
    }
  },
  [types.SET_DIETS](state, action) {
    return {
      ...state,
      diets: action.diets,
    }
  },
})
