import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { API } from 'aws-amplify'
import PFRM_API from '../config/api'
import { selectors as petSelectors } from '../pets'
import { selectors as breedSelectors } from '../breeds'
import { actions, types } from '../targetNutrition'
import { actions as dietActions } from '../diets'

export function* fetchTargetNutrition() {
  try {
    const currentPet = yield select(petSelectors.currentPet)
    let age = ''
    let size = ''
    if (currentPet.age) {
      age = currentPet.age
      if (currentPet.primaryBreed) {
        const breed = yield select(breedSelectors.breed(currentPet.primaryBreed))
        size = breed.size_id
      } else if (currentPet.size) {
        size = currentPet.size
      }
    }

    if (age && size) {
      const docs = yield call(
        [API, 'get'],
        PFRM_API,
        `/diets/target-nutrition?ageCategoryId=${age}&breedSizeId=${size}`
      )
      yield put(actions.set(docs))
      yield put(dietActions.getRelatedDiets())
    }
  } catch (error) {
    console.log(error)
  }
}

export default function*() {
  yield all([takeEvery(types.GET, fetchTargetNutrition)])
}
