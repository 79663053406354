import React from 'react'
import { type } from 'ramda'
import Header from './panel/Header'
import DataItem from './panel/DataItem'
import Button from './panel/Button'

export class Panel extends React.Component {
  constructor(props) {
    super(props)

    if (this.props.isOpen) {
      this.state = {
        isOpen: true,
        height: 'auto',
        transition: 'none',
        inTransition: false,
      }
    } else {
      this.state = {
        isOpen: false,
        height: 0,
        transition: 'height 400ms linear',
        overflow: 'hidden',
        inTransition: false,
      }
    }
  }

  innerRef = React.createRef()

  componentDidUpdate(prevProps, prevState) {
    if (this.state.shouldOpenOnNextCycle) {
      this.continueOpen()
    }

    if (prevState.height === 'auto' && this.state.shouldSwitchAutoOnNextCycle) {
      window.setTimeout(() => {
        // Set small timeout to ensure a true re-render
        this.setState({
          height: 0,
          overflow: 'hidden',
          isOpen: false,
          shouldSwitchAutoOnNextCycle: false,
        })
      }, 50)
    }

    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.open === true) {
        this.open()
      } else {
        this.close()
      }
    }
  }

  close = () => {
    this.setState({
      shouldSwitchAutoOnNextCycle: true,
      height: this.innerRef.current.scrollHeight,
      transition: 'height 400ms linear',
      inTransition: true,
    })
  }

  open = () => {
    this.setState({
      shouldOpenOnNextCycle: true,
      inTransition: true,
    })
  }

  continueOpen = () => {
    this.setState({
      height: this.innerRef.current.scrollHeight,
      transition: `height 400ms linear`,
      isOpen: true,
      inTransition: true,
      shouldOpenOnNextCycle: false,
    })
  }

  toggleOpen = event => {
    event.preventDefault()

    if (this.state.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  handleTransitionEnd = () => {
    // Switch to height auto to make the container responsive
    if (this.state.isOpen) {
      this.setState({ height: 'auto', overflow: 'visible', inTransition: false })
    } else {
      this.setState({ inTransition: false })
    }
  }

  render() {
    var openClass = this.state.isOpen ? 'is-open' : 'is-closed'
    var collapseStyle = {
      height: this.state.height,
      WebkitTransition: this.state.transition,
      msTransition: this.state.transition,
      transition: this.state.transition,
      overflow: this.state.overflow,
    }

    const { header, headerBackgroundColor, data, collapsible } = this.props
    return (
      <div>
        {collapsible ? (
          <Header backgroundColor={headerBackgroundColor} role="button" onClick={this.toggleOpen}>
            {header}
          </Header>
        ) : (
          <Header backgroundColor={headerBackgroundColor}>{header}</Header>
        )}
        <div
          className={openClass}
          ref={this.innerRef}
          onTransitionEnd={this.handleTransitionEnd}
          style={collapsible && collapseStyle}
        >
          {data.map(data => (
            <DataItem key={`di-${data}`} justify="space-between">
              {type(data) === 'Array' ? (
                data.map((d, i) => <div key={`${d}-${i}`}>{d}</div>)
              ) : (
                <div key={data}>{data}</div>
              )}
            </DataItem>
          ))}
        </div>
        {collapsible && (
          <Button onClick={this.toggleOpen}>
            {this.state.isOpen ? (
              <i className="fas fa-angle-up" />
            ) : (
              <i className="fas fa-angle-down" />
            )}
          </Button>
        )}
      </div>
    )
  }
}

Panel.displayName = 'Panel'

Panel.defaultProps = {
  isOpen: false,
}

export default Panel
