import styled from 'styled-components'

export const Item = styled.div`
  order: ${props => props.order || '0'};
  flex: ${props => props.flex || '0 1 auto'};
  align-self: ${props => props.alignSelf || 'auto'};
`

Item.displayName = 'Item'

export default Item
