import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

const FooterStyles = styled.div`
  background-color: ${props => props.theme.TEAL};
  padding: ${props => props.theme.SPACING.STANDARD};
  margin: ${props => props.theme.SPACING.STANDARD} -${props => props.theme.SPACING.STANDARD} 0 -${props =>
      props.theme.SPACING.STANDARD};
  color: ${props => props.theme.WHITE};
  font-size: 1.1rem;
`

const Footer = () => <FooterStyles>© {moment().get('year')} Pet Food Reference Manual</FooterStyles>

FooterStyles.displayName = 'FooterStyles'
Footer.displayName = 'Footer'

export default Footer
