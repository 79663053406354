// types
export const types = {
  ASYNC_START: 'shared/ASYNC_START',
  ASYNC_END: 'shared/ASYNC_END',
  ASYNC_ERROR: 'shared/ASYNC_ERROR',
  GET_REF_DATA: 'shared/GET_REF_DATA',
}

// action creators
const startAsync = () => ({ type: types.ASYNC_START })
const endAsync = () => ({ type: types.ASYNC_END })
const errorAsync = error => ({ type: types.ASYNC_ERROR, error })
const getRefData = () => ({ type: types.GET_REF_DATA })

export const actions = {
  startAsync,
  endAsync,
  errorAsync,
  getRefData,
}

// selectors
export const selectors = {
  asyncOperationsCount: state => state.shared.asyncOperationsCount,
}

export default actions
