import React from 'react'
import Button from '../components/Button'
import Card from '../components/Card'
import Flex from '../components/Flex'
import NutritionTarget from './header/NutritionTarget'
import PetInformation from './header/PetInformation'

export const Header = ({ currentPet, targetNutrition, breed }) => (
  <Card>
    <Flex alignItems="center" justify="space-between">
      <Flex flex="1 0">
        <PetInformation
          name={currentPet.petName}
          age={currentPet.age}
          breed={breed && breed.name ? breed.name : 'N/A'}
        />
      </Flex>
      <Flex justify="space-around" flex="2 0">
        <NutritionTarget label="Target Protein" value={targetNutrition.protein} />
        <NutritionTarget label="Target Fat" value={targetNutrition.fat} />
        <NutritionTarget label="Target Fiber" value={targetNutrition.fiber} />
      </Flex>
      <Flex justify="flex-end" flex="1 0">
        <Button primary>Email Results</Button>
      </Flex>
    </Flex>
  </Card>
)

Header.displayName = 'Header'

export default Header
