import { all, takeLatest, fork, call, put } from 'redux-saga/effects'
import { actions, types } from '../shared'

import { fetchAllBreeds } from '../breeds/breeds.saga'
import { fetchAllManufacturers } from '../manufacturers/manufacturers.saga'

function* fetchAllRefData() {
  yield fork(fetchAllBreeds)
  yield fork(fetchAllManufacturers)
}

function* fetchRefData() {
  try {
    yield put(actions.startAsync())
    yield call(fetchAllRefData)
    yield put(actions.endAsync())
  } catch (error) {
    yield put(actions.endAsync())
    console.error(error)
  }
}

export default function*() {
  yield all([takeLatest(types.GET_REF_DATA, fetchRefData)])
}
