export default {
  BACKGROUND: '#F4F4F4',
  LIGHTEST_GRAY: '#F1F1F1',
  LIGHT_GRAY: '#F6F6F5',
  BORDER_GRAY: '#D8D8D8',
  DEFAULT_TEXT: '#636363',
  TEAL: '#5EA3AF',
  WHITE: '#FFF',
  CREAM: '#FFFFc3',
  BLUE: '#4B92A1',
  ORANGE: '#F9872D',
  YELLOW: '#F9B82A',
  GREEN: '#B4BD34',
  BOX_SHADOW: 'rgba(0,0,0,0.5)',

  BUTTONS: {
    DEFAULT: '#FFF',
    PRIMARY: '#ebc125'
  },

  SPACING: {
    EXTRA_SMALL: '0.5rem',
    SMALL: '0.8rem',
    MEDIUM: '1.2rem',
    STANDARD: '1.5rem',
    LARGE: '1.8rem',
    EXTRA_LARGE: '2rem'
  }
}
