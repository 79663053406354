import React from 'react'
import styled from 'styled-components'
import Flex from '../../components/Flex'
import formatPercent from '../../../util/formatPercent'

const CalorieTargetStyles = styled(Flex)`
  font-size: 3.6rem;
  margin: 0 1rem;

  .nt-label {
    font-size: 1.2rem;
    color: ${props => props.theme.TEAL};
    line-height: 1.9;
  }

  .is-target {
    font-size: 2.5em;
  }
`

const CalorieTarget = ({ label, value, isTarget, children }) => (
  <CalorieTargetStyles direction="column" alignItems="center">
    <div className={isTarget ? 'is-target' : ''}>{value}</div>
    <span className="nt-label">{label}</span>
  </CalorieTargetStyles>
)

CalorieTarget.displayName = 'Calorie Target'

export default CalorieTarget
