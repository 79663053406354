import { all, call, takeEvery, put, select } from 'redux-saga/effects'
import { API } from 'aws-amplify'
import PFRM_API from '../config/api'
import { actions, types } from '../diets'
import { selectors as petSelectors } from '../pets'
import { selectors as breedSelectors } from '../breeds'

export function* getDiet() {
  try {
    const currentPet = yield select(petSelectors.currentPet)
    const currentDiet = yield call([API, 'get'], PFRM_API, `/diets/${currentPet.currentDietName}`)
    yield put(actions.setCurrentDiet(currentDiet))
  } catch (error) {
    console.error(error)
  }
}

export function* getRelatedDiets() {
  try {
    const currentPet = yield select(petSelectors.currentPet)
    const breed = yield select(breedSelectors.breed(currentPet.primaryBreed))
    const relatedDiets = yield call(
      [API, 'get'],
      PFRM_API,
      `/diets/recommended?ageCategoryId=${currentPet.age}&breedSizeId=${breed.size_id}&breedId=${
        currentPet.primaryBreed
      }&foodPreference=${currentPet.foodPreference}&limit=${4}`
    )

    yield put(actions.setRelatedDiets(relatedDiets))
  } catch (error) {
    console.error(error)
  }
}

export default function*() {
  yield all([
    takeEvery(types.GET_CURRENT_DIET, getDiet),
    takeEvery(types.GET_RELATED_DIETS, getRelatedDiets),
  ])
}
