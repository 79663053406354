import React from 'react'
import {
  Authenticator,
  SignIn,
  ForgotPassword,
  RequireNewPassword,
  ConfirmSignIn,
} from 'aws-amplify-react'
import theme from '../theme'
import logo from '../static/logo.svg'

const authTheme = {
  container: {
    background: theme.TEAL,
    minHeight: '100%',
    minHeight: '100vh',
    display: 'flex',
  },
  formContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionHeader: {
    backgroundImage: `url(${logo})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: 0,
    width: '100%',
    paddingTop: '66.64%',
  },
  sectionFooter: {
    width: '100%',
  },
  sectionFooterPrimaryContent: {
    width: '100%',
  },
  button: {
    width: '100%',
    backgroundColor: theme.BUTTONS.PRIMARY,
  },
  signInButton: {
    width: '100%',
    backgroundColor: theme.BUTTONS.PRIMARY,
  },
}

const AppAuth = props => {
  return (
    <Authenticator hideDefault={true} theme={authTheme}>
      <SignIn />
      <ConfirmSignIn />
      <RequireNewPassword />
      <ForgotPassword />
      {props.children}
    </Authenticator>
  )
}

AppAuth.displayName = 'AppAuth'

export default AppAuth
