// types
export const types = {
  GET_PET_PARENTS: 'vets/GET_PET_PARENTS',
  SET_PET_PARENTS: 'vets/SET_PET_PARENTS',
}

// action creators
/**
 * Dispatches vets/GET_PET_PARENTS
 * @param {number} vetId
 */
const getPetParents = vetId => ({
  type: types.GET_PET_PARENTS,
  vetId,
})

/**
 * Dispatches vets/SET_PET_PARENTS
 * @param {Array<{}>} petParents
 */
const setPetParents = petParents => ({
  type: types.SET_PET_PARENTS,
  petParents,
})

export const actions = {
  getPetParents,
  setPetParents,
}

// selectors
export const selectors = {
  petParents: state => state.vet.petParents,
}
