import styled from 'styled-components'

export const Title = styled.h2`
  font-size: 1.8rem;
  color: ${props => props.theme.TEAL};
  margin: ${props => props.theme.SPACING.SMALL} 0 0 0;
`

Title.displayName = 'Title'

export default Title
