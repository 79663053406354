import React from 'react'
import styled from 'styled-components'
import Flex from '../../components/Flex'
import formatPercent from '../../../util/formatPercent'

const NutritionTargetStyles = styled(Flex)`
  font-size: 3.6rem;
  margin: 0 1rem;

  .nt-label {
    font-size: 1.2rem;
    color: ${props => props.theme.TEAL};
    line-height: 1.9;
  }
`

const NutritionTarget = ({ label, value, children }) => (
  <NutritionTargetStyles direction="column" alignItems="center">
    {formatPercent(value, 0)}
    <span className="nt-label">{label}</span>
  </NutritionTargetStyles>
)

NutritionTarget.displayName = 'NutritionTarget'

export default NutritionTarget
