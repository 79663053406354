import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { pipe } from 'ramda'
import { selectors as breedSelectors } from '../store/breeds'
import {
  selectors as manufacturerSelectors,
  actions as manufacturerActions,
} from '../store/manufacturers'
import { selectors as petSelectors, actions as petActions } from '../store/pets'
import preventDefaultEvent from '../util/preventDefaultEvent'
import Divider from './components/Divider'
import TextBox from './home/TextBox'
import Actions from './home/Actions'

export const Home = ({
  allDiets,
  breeds,
  currentPet,
  manufacturers,
  // saveNewPet,
  history,
  updateField,
  resetForm,
  setCurrentDiet,
  getDietsForManufacturer,
}) => {
  const updateFieldHandler = event => {
    updateField([event.currentTarget.name], event.currentTarget.value)
    return event.currentTarget.value
  }

  const saveNewPetHandler = pipe(
    preventDefaultEvent,
    () => {
      // saveNewPet()
      history.push('/results')
    }
  )

  const getDietsHandler = manufacturerBrand => {
    const [manufacturerId, brandId] = manufacturerBrand.split('_')

    getDietsForManufacturer(manufacturerId, brandId === 'null' ? null : brandId)
  }
  return (
    <div className="container">
      <h4>First a Few Questions</h4>
      <Divider />
      <form className="form-horizontal">
        <TextBox
          labelText="Pet Parent Name"
          id="parentName"
          placeholder="Pet Parent Name"
          value={currentPet.parentName}
          onChange={updateFieldHandler}
        />
        <TextBox
          labelText="Pet Parent Email"
          id="email"
          placeholder="Pet Parent email"
          value={currentPet.email}
          onChange={updateFieldHandler}
        />
        <TextBox
          labelText="Pet Name"
          id="petName"
          placeholder="Pet Name"
          value={currentPet.petName}
          onChange={updateFieldHandler}
        />

        <div className="form-group">
          <label htmlFor="breed" className="col-sm-2 control-label">
            Primary Breed
          </label>
          <div className="col-sm-10">
            <select
              className="form-control"
              id="primaryBreed"
              name="primaryBreed"
              value={currentPet.primaryBreed}
              onChange={updateFieldHandler}
              required
            >
              <option value="">--</option>
              {breeds.map(b => (
                <option value={b.id} key={b.id}>
                  {b.name}
                </option>
              ))}
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        {currentPet.primaryBreed === 'Other' ? (
          <div className="form-group">
            <label htmlFor="size" className="col-sm-2 control-label">
              Size
            </label>
            <div className="col-sm-10">
              <label className="radio-inline">
                <input
                  type="radio"
                  name="size"
                  id="size1"
                  value="1"
                  required
                  onChange={updateFieldHandler}
                />
                Toy
              </label>
              <label className="radio-inline">
                <input
                  type="radio"
                  name="size"
                  id="size2"
                  value="2"
                  required
                  onChange={updateFieldHandler}
                />
                Small
              </label>
              <label className="radio-inline">
                <input
                  type="radio"
                  name="size"
                  id="size3"
                  value="3"
                  required
                  onChange={updateFieldHandler}
                />
                Medium
              </label>
              <label className="radio-inline">
                <input
                  type="radio"
                  name="size"
                  id="size4"
                  value="4"
                  required
                  onChange={updateFieldHandler}
                />
                Large
              </label>
              <label className="radio-inline">
                <input
                  type="radio"
                  name="size"
                  id="size5"
                  value="5"
                  required
                  onChange={updateFieldHandler}
                />
                Giant
              </label>
            </div>
          </div>
        ) : null}
        <div className="form-group">
          <label htmlFor="age" className="col-sm-2 control-label">
            Age
          </label>
          <div className="col-sm-10">
            <label className="radio-inline">
              <input
                type="radio"
                name="age"
                id="age1"
                value="1"
                required
                onChange={updateFieldHandler}
              />{' '}
              Puppy
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="age"
                id="age2"
                value="2"
                required
                onChange={updateFieldHandler}
              />{' '}
              Adult
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="age"
                id="age3"
                value="3"
                required
                onChange={updateFieldHandler}
              />{' '}
              Senior
            </label>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="gender" className="col-sm-2 control-label">
            Gender
          </label>
          <div className="col-sm-10">
            <label className="radio-inline">
              <input
                type="radio"
                name="gender"
                id="gender1"
                value="Male"
                onChange={updateFieldHandler}
                required
              />{' '}
              Male
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="gender"
                id="gender2"
                value="Female"
                onChange={updateFieldHandler}
                required
              />{' '}
              Female
            </label>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="foodPreference" className="col-sm-2 control-label">
            Food Preference
          </label>
          <div className="col-sm-10">
            <label className="radio-inline">
              <input
                type="radio"
                name="foodPreference"
                id="foodPreference1"
                value="Wet Food"
                onChange={updateFieldHandler}
                required
              />{' '}
              Can
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="foodPreference"
                id="foodPreference2"
                value="Dry Food"
                onChange={updateFieldHandler}
                required
              />{' '}
              Dry
            </label>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="bodyComposition" className="col-sm-2 control-label">
            Body Composition
          </label>
          <div className="col-sm-10">
            <label className="radio-inline">
              <input
                type="radio"
                name="bodyComposition"
                id="bodyComposition1"
                value="underWeight"
                onChange={updateFieldHandler}
                required
              />{' '}
              Underweight
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="bodyComposition"
                id="bodyComposition2"
                value="ideal"
                onChange={updateFieldHandler}
                required
              />{' '}
              Ideal
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="bodyComposition"
                id="bodyComposition3"
                value="overWeight"
                onChange={updateFieldHandler}
                required
              />{' '}
              Overweight
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="bodyComposition"
                id="bodyComposition4"
                value="obese"
                onChange={updateFieldHandler}
                required
              />{' '}
              Obese
            </label>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="activityLevel" className="col-sm-2 control-label">
            Activity Level
          </label>
          <div className="col-sm-10">
            <label className="radio-inline">
              <input
                type="radio"
                name="activityLevel"
                id="activityLevel1"
                value="lessActive"
                onChange={updateFieldHandler}
                required
              />{' '}
              Less Active
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="activityLevel"
                id="activityLevel2"
                value="normal"
                onChange={updateFieldHandler}
                required
              />{' '}
              Normal
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="activityLevel"
                id="activityLevel3"
                value="veryActive"
                onChange={updateFieldHandler}
                required
              />{' '}
              Very Active
            </label>
          </div>
        </div>
        {/* <div className="form-group">
          <label htmlFor="currentDiet" className="col-sm-2 control-label">
            Current Diet
          </label>
          <div className="col-sm-5">
            <select
              className="form-control"
              id="currentDietManufacturer"
              name="currentDietManufacturer"
              onChange={pipe(
                updateFieldHandler,
                getDietsHandler
              )}
              value={currentPet.currentDietManufacturer}
              required
            >
              <option value="">Select a Manufacturer</option>
              {manufacturers.map(m => (
                <option value={`${m.id}_${m.brandId}`} key={`${m.id}_${m.brandId}`}>
                  {m.manufacturerBrandName}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-5">
            <select
              className="form-control"
              id="currentDietName"
              name="currentDietName"
              onChange={pipe(
                updateFieldHandler
                // setCurrentDiet
              )}
              value={currentPet.currentDietName}
              required
            >
              <option value="">Select the Manufacturer's Diet</option>
              {allDiets.map(d => (
                <option value={d.id} key={d.id}>
                  {d.productName}
                </option>
              ))}
            </select>
          </div>
        </div> */}
        <Actions onReset={resetForm} onSave={saveNewPetHandler} />
      </form>
    </div>
  )
}

const mapStateToProps = /* istanbul ignore next */ state => ({
  breeds: breedSelectors.breeds(state),
  currentPet: petSelectors.currentPet(state),
  manufacturers: manufacturerSelectors.manufacturers(state),
  allDiets: manufacturerSelectors.diets(state),
})

const mapDispatchToProps = /* istanbul ignore next */ dispatch => ({
  getDietsForManufacturer: bindActionCreators(manufacturerActions.getDiets, dispatch),
  resetForm: bindActionCreators(petActions.resetCurrentPet, dispatch),
  // saveNewPet: bindActionCreators(petActions.saveNewPet, dispatch),
  // setCurrentDiet: bindActionCreators(dietActions.setCurrentDiet, dispatch),
  updateField: bindActionCreators(petActions.updateField, dispatch),
})

Home.displayName = 'Home'

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
