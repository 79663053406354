import React from 'react'
import ReactDOM from 'react-dom'
import Amplify from 'aws-amplify'
import AppAuth from './app/AppAuth'
import awsConfig from './aws-exports'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import configureStore from './Store'
import App from './App'
import registerServiceWorker from './registerServiceWorker'

Amplify.configure(awsConfig)
const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AppAuth>
        <App />
      </AppAuth>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)
registerServiceWorker()
