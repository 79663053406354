import React from 'react'
import { onlyUpdateForKeys } from 'recompose'

export const TextBox = ({ labelText, id, placeholder, value, onChange, disabled }) => (
  <div className="form-group">
    <label htmlFor={id} className="col-sm-2 control-label">
      {labelText}
    </label>
    <div className="col-sm-10">
      <input
        type="text"
        className="form-control"
        id={id}
        name={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  </div>
)

TextBox.displayName = 'TextBox'

export default onlyUpdateForKeys(['value'])(TextBox)
