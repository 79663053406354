/**
 * Calls prevent default then returns the event for composition
 *  @param {Event} event
 *  @returns {Event} the original event passed in
 */
const preventDefaultEvent = event => {
  event.preventDefault()
  return event
}

export default preventDefaultEvent
