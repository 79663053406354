import { types } from '../breeds'
import createReducer from '../utils/createReducer'

export const initialState = {
  breeds: [],
}

export default createReducer(initialState, {
  [types.SET](state, action) {
    return {
      ...state,
      breeds: action.breeds,
    }
  },
})
