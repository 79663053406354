// types
export const types = {
  SIGN_IN: 'user/SIGN_IN',
  SIGN_IN_SUCCESS: 'user/SIGN_IN_SUCCESS',
  SIGN_OUT: 'user/SIGN_OUT',
  SIGN_OUT_SUCCESS: 'user/SIGN_OUT_SUCCESS',
}

// action creators
/**
 * Dispatches user/SIGN_IN
 * @param {string} username
 * @param {string} password
 */
const signIn = (username, password) => ({
  type: types.SIGN_IN,
  username,
  password,
})

const signInSuccess = user => ({
  type: types.SIGN_IN_SUCCESS,
  user,
})

const signOut = () => ({
  type: types.SIGN_OUT,
})

const signOutSuccess = () => ({
  type: types.SIGN_OUT_SUCCESS,
})

export const actions = {
  signIn,
  signInSuccess,
  signOut,
  signOutSuccess,
}

// selectors
export const selectors = {
  user: state => state.user.user,
}

export default actions
