import styled from 'styled-components'
import { lighten, darken } from 'polished'

export const Button = styled.button.attrs(props => ({
  type: props.type || 'button',
}))`
  border: 1px solid;
  border-radius: 3px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-block;
  font-size: ${props => {
    const baseFontSize = '14px'
    const baseFontSizeParsed = parseInt(baseFontSize, 10)
    return (
      (props.small && `${baseFontSizeParsed * 0.875}px`) ||
      (props.large && `${baseFontSizeParsed * 1.375}px`) ||
      baseFontSize
    )
  }};
  font-weight: 400;
  line-height: 1.5;
  min-width: 100px;
  padding: 10px 20px;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  text-transform: uppercase;
  user-select: none;
  margin: ${props => props.margin || '0'};
  width: ${props => (props.expand ? '100%' : 'auto')};
  ${props => {
    const { PRIMARY, DEFAULT } = props.theme.BUTTONS
    let backgroundColor = DEFAULT
    let color = props.theme.DEFAULT_TEXT

    // what do we have disabled, inverted, primary, default
    if (props.primary) {
      backgroundColor = PRIMARY
      color = DEFAULT
    }

    if (props.disabled) {
      backgroundColor = lighten(0.05, backgroundColor)
      color = lighten(0.05, color)
    }

    if (props.link) {
      return `
    color: ${props.inverted ? backgroundColor : color};
    background-color: transparent;
    border-color: transparent;
    &:hover {
      text-decoration: underline
    }
        `
    }

    return `
    color: ${props.inverted ? backgroundColor : color};
    background-color: ${props.inverted ? color : backgroundColor};
    border-color: ${props.inverted ? backgroundColor : darken(0.05, backgroundColor)};
    &:hover {
      color: ${color};
      background-color: ${props.inverted ? backgroundColor : darken(0.05, backgroundColor)};
    }
    `
  }};
`

Button.displayName = 'Button'

export default Button
