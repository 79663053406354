import styled from 'styled-components'

export const Button = styled.button`
  border: none;
  text-align: center;
  background-color: ${props => props.theme.LIGHT_GRAY};
  width: 100%;
  border: 1px solid ${props => props.theme.LIGHTEST_GRAY};
  border-radius: 3px;
  margin-top: 5px;

  padding: ${props => props.theme.SPACING.MEDIUM} ${props => props.theme.SPACING.STANDARD};
`

Button.displayName = 'Button'

export default Button
