import styled from 'styled-components'
import Flex from '../../../components/Flex'

export const DataItem = styled(Flex)`
  padding: ${props => props.theme.SPACING.SMALL};

  &:nth-child(odd) {
    background-color: ${props => props.theme.LIGHT_GRAY};
  }

  &:hover {
    background-color: ${props => props.theme.CREAM};
  }
`

DataItem.displayName = 'DataItem'

export default DataItem
