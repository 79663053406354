import React from 'react'
import styled from 'styled-components'

const PetInformationStyles = styled.div`
  .pi-name {
    font-size: 3.6rem;
  }

  .pi-subtext {
    font-size: 1.2rem;
    font-style: italic;
  }
`

const getAge = ageId => {
  switch (parseInt(ageId)) {
    case 1:
      return 'Puppy'
    case 2:
      return 'Adult'
    case 3:
      return 'Senior'
    default:
      return 'N/A'
  }
}

export const PetInformation = ({ name, age, breed }) => (
  <PetInformationStyles>
    <div className="pi-name">{name || 'N/A'}</div>
    <div className="pi-subtext">
      {getAge(age)} | {breed || 'Unknown Breed'} {breed === 'Other' && 'Breed'}
    </div>
  </PetInformationStyles>
)

PetInformation.displayName = 'PetInformation'

PetInformation.defaultProps = {
  name: 'N/A',
  age: 'Puppy',
  breed: 'N/A',
}

export default PetInformation
