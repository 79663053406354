import { all, takeEvery, call, put } from 'redux-saga/effects'
import { API } from 'aws-amplify'
import { types, actions } from '../vet'
import { actions as sharedActions } from '../shared'

export function* fetchPetParents({ vetId }) {
  try {
    yield put(sharedActions.startAsync())
    let petParents = yield call([API, 'get'], 'pfrmapi', `/vets/${vetId}/pet-parents`)
    yield put(actions.setPetParents(petParents))
  } catch (error) {
    console.error(error)
    yield put(sharedActions.errorAsync(error))
  } finally {
    yield put(sharedActions.endAsync())
  }
}

export default function*() {
  yield all([takeEvery(types.GET_PET_PARENTS, fetchPetParents)])
}
