import { types } from '../diets'
import createReducer from '../utils/createReducer'

export const initialState = {
  diets: [],
  currentDiet: {},
  relatedDiets: [],
}

export default createReducer(initialState, {
  [types.SET_ALL](state, action) {
    return {
      ...state,
      diets: action.diets,
    }
  },
  [types.SET_CURRENT_DIET](state, action) {
    return {
      ...state,
      currentDiet: action.diet,
    }
  },
  [types.SET_RELATED_DIETS](state, action) {
    return {
      ...state,
      relatedDiets: action.diets,
    }
  },
})
