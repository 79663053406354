//types
export const types = {
  GET: 'breeds/GET',
  SET: 'breeds/SET',
}

// action creators
const get = () => ({ type: types.GET })
const set = breeds => ({ type: types.SET, breeds })

export const actions = {
  get,
  set,
}

// selectors
export const selectors = {
  breeds: state => state.breeds.breeds,
  breed: id => state => state.breeds.breeds.find(b => b.id === parseInt(id)),
}

export default actions
