// types
export const types = {
  GET_CURRENT_DIET: 'diets/GET_CURRENT',
  SET_CURRENT_DIET: 'diets/SET_CURRENT',
  GET_RELATED_DIETS: 'diets/GET_RELATED',
  SET_RELATED_DIETS: 'diets/SET_RELATED',
}

// action creators
const getCurrentDiet = diets => ({ type: types.GET_CURRENT_DIET })
const setCurrentDiet = diet => ({ type: types.SET_CURRENT_DIET, diet })
const getRelatedDiets = () => ({ type: types.GET_RELATED_DIETS })
const setRelatedDiets = diets => ({ type: types.SET_RELATED_DIETS, diets })

export const actions = {
  getCurrentDiet,
  setCurrentDiet,
  getRelatedDiets,
  setRelatedDiets,
}

// selectors
export const selectors = {
  allDiets: state => state.diets.diets,
  currentDiet: state => state.diets.currentDiet,
  relatedDiets: state => state.diets.relatedDiets,
}

export default actions
