import React from 'react'
import styled from 'styled-components'

const PetInformationForCaloriesStyles = styled.div`
  .pi-name {
    font-size: 3.6rem;
  }

  .pi-subtext {
    font-size: 1.2rem;
    font-style: italic;
  }
`

const getGender = val => {
  let retVal = 'Unknown Gender'
  if (val) {
    retVal = val
  }
  return retVal
}

const isPregnant = val => {
  let retVal = 'Not Pregnant'
  if (val) {
    if (val.toLowerCase() === 'yes') {
      retVal = 'Is Pregnant'
    }
  }
  return retVal
}

const isSpayedNeutered = (val, gender) => {
  let isVal = val === 'spayedNeutered' ? true : false
  if (gender === 'Female') {
    return isVal ? 'Is Spayed' : 'Is Not Spayed'
  } else if (gender === 'Male') {
    return isVal ? 'Is Neutered' : 'Is Not Neutered'
  } else {
    return isVal ? 'Is Spayed or Neutered' : 'Is Not Spayed or Neutered'
  }
}

const getWeight = val => {
  let retVal = 'Unknown Weight'
  if (val) {
    let intVal = parseInt(val)
    if (intVal) {
      retVal = intVal + ' lb.'
    }
  }
  return retVal
}

const getBodyComposition = val => {
  let retVal = 'Unknown Body Composition'
  if (val) {
    let normVal = val.toLowerCase()
    if (normVal === 'underweight') {
      retVal = 'Underweight'
    } else if (normVal === 'ideal') {
      retVal = 'Ideal'
    } else if (normVal === 'overweight') {
      retVal = 'Overweight'
    } else {
      retVal = 'Obese'
    }
  }
  return retVal
}

const getActivityLevel = val => {
  let retVal = 'Unknown Activity Level'
  if (val) {
    let normVal = val.toLowerCase()
    if (normVal === 'lessactive') {
      retVal = 'Less Active'
    } else if (normVal === 'normal') {
      retVal = 'Normally Active'
    } else {
      retVal = 'Very Active'
    }
  }
  return retVal
}

export const PetInformationForCalories = ({
  name,
  age,
  petWeight,
  breed,
  gender,
  bodyComposition,
  activityLevel,
  spayNeuter,
  pregnant,
  climateType
}) => (
  <PetInformationForCaloriesStyles>
    <div className="pi-name">{name || 'N/A'}</div>
    <div className="pi-subtext">
      {age || 'Puppy'}, {breed || 'Unknown Breed'}, {getWeight(petWeight)}, {getGender(gender)},{' '}
      {getBodyComposition(bodyComposition)}, {getActivityLevel(activityLevel)},{' '}
      {isSpayedNeutered(spayNeuter, gender)}, {isPregnant(pregnant)},{' '}
      {climateType || 'Unknown Climate Type'}
    </div>
  </PetInformationForCaloriesStyles>
)

PetInformationForCalories.displayName = 'PetInformationForCalories'

PetInformationForCalories.defaultProps = {
  name: 'N/A',
  age: 'Puppy',
  breed: 'N/A'
}

export default PetInformationForCalories
